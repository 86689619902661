import DOMPurify from 'isomorphic-dompurify';
import Image from 'next/future/image';
import Link from 'next/link';

import { getSection } from '../utility';

const ContactFreeRentals = (props: any) => {
  const section = getSection(props);

  const title = section && section[8] && section[8].Title;
  const titleBanner = section && section[9] && section[9].Title;
  const subTitleBanner = section && section[9] && section[9].Sub_Title;
  const link = section && section[9] && section[9].Link;
  const imgData = section && section[9] && section[9].Background_Image;
  return (
    <div className="container-xl custom-container px-0">
      <section className="free-rentals">
        <h2 className="section-heading">{title}</h2>

        <div className="image-overlay-info">
          <Image
            sizes="(max-width: 375px) 320px, (max-width: 576px) 576px, (max-width: 768px) 768px, (max-width: 992px) 992px, (max-width: 1280px) 1280px"
            src={imgData?.data?.attributes?.url || ''}
            alt={
              imgData?.data?.attributes?.alternativeText ||
              imgData?.data?.attributes?.name
            }
            className="overlay-img"
            width="1280"
            height="490"
          />
          <div className="overlay-info">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-7 col-sm-12">
                  <h4 className="heading">{titleBanner}</h4>
                  <div
                    className="info"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(subTitleBanner),
                    }}
                  ></div>
                  <Link href="/self-storage">
                    <button type="button" className="btn as-primary-btn">
                      {link?.Text}
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default ContactFreeRentals;
